import React from 'react';


const Splitter = () => { 

return(

<div className="splitter" style={{width:'100vw',
  height: '200px',}}></div>

)
}

export default Splitter